import { TouchableOpacity, useWindowDimensions, View } from 'react-native';
import AppText from './AppText';
import { combineNames, isMobile } from '../../services/utils';
import React, { useState } from 'react';
import { ProfilePicture } from './ProfilePicture';
import { UserProfileOverlay } from './UserProfileOverlay';
import { FONT_FAMILY_DEFAULT } from '../../styles/globalStyle';
import { User } from '../../entities/User';
import { PRIMARY_COLOR } from '../../styles/appColor';

export interface ProfileCardProps {
  user: User;
}

export function ProfileCard(props: ProfileCardProps) {
  const [showProfile, setShowProfile] = useState(false);
  const { width } = useWindowDimensions();

  const isDeleted = props.user.aspNetUsers?.deletedAt !== null;

  return (
    <>
      <TouchableOpacity
        disabled={isDeleted}
        onPress={() => setShowProfile(true)}
        style={{ flexDirection: 'row', opacity: isDeleted ? 0.5 : 1 }}
      >
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            borderStyle: 'dashed',
            borderColor: PRIMARY_COLOR,
            borderWidth: 2,
            borderRadius: 4,
            padding: 8,
            margin: 8,
            width: isMobile() ? width * 0.5 - 16 : 200,
          }}
        >
          <ProfilePicture pictureId={props.user.fichierIdProfil} size="medium" />
          <View style={{ flexDirection: 'row', flex: 1, justifyContent: 'center', width: '100%' }}>
            <AppText
              numberOfLines={1}
              style={{
                fontSize: 14,
                fontFamily: FONT_FAMILY_DEFAULT.bold,
              }}
            >
              {combineNames(props.user)}
            </AppText>
          </View>
        </View>
      </TouchableOpacity>
      <UserProfileOverlay
        onBackdropPress={() => setShowProfile(false)}
        userProfileData={props.user}
        isVisible={showProfile}
      />
    </>
  );
}
